import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'projects/_shared/shared/helper-service/user.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { IconType, TopNavMenuItem, FooterMenuModel, ImageModel } from 'customerdigital-ui-components-lib';
import { HeaderMenuItemType, FooterMenuItemType } from './shared/enum';
import { Subscription } from 'rxjs';
import { RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { AccessibilityHelperService, LoginService, TimeoutService, UrlBuilderService } from 'customerdigital-service-lib';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'projects/_shared/shared/constants';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { Store } from '@ngrx/store';
import { SessionTimeoutService } from 'projects/_shared/shared/helper-service/session-timeout.service';
import { SessionTimeoutModel } from 'projects/_shared/shared/models/sessionTimeout.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  sessionTimeoutConfig!:SessionTimeoutModel;
  idleTime!:number;
  timeoutTime!:number;
  previousUrl: string = "";
  currentUrl: string = ""
  skipToMainUrl: string = "";
  mainHeader: any;
  routerEventSubscribe: any;
  footerMenuItems = new Array();
  dataLoaded!: boolean;
  copyRightText: string = "";
  copyRightTextMobile: string = "";

  hasLoggedInStateSubscription: Subscription = new Subscription();
  dataLoadedSubscription: Subscription = new Subscription();

  constructor(private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private loginService: LoginService,
    private urlBuilderService: UrlBuilderService,
    private translateService: TranslateService,
    private accessibilityService: AccessibilityHelperService,
    private store: Store<IAppState>,
    private timeoutService: TimeoutService,
    private sessionTimeoutService: SessionTimeoutService) {

    this.dataLoadedSubscription = this.store.select<boolean | undefined>(state => state.DataLoaded)
      .subscribe(loaded => this.dataLoaded = loaded!);
  }



  ngOnInit(): void {
    // Code To start ngIdle service after sso authentication completed
    this.store.select(x=> x.EnvironmentConfig?.IDLE_SESSION).subscribe(s=> this.idleTime = s ?? 0)
    this.store.select(x=> x.EnvironmentConfig?.TIME_OUT_SESSION).subscribe(s=> this.timeoutTime = s ?? 0)
    this.sessionTimeoutService.handleIdleSessionTimeOut();
    this.routerEventSubscribe = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.skipToMainUrl = `${event.url}${Constants.SkipToMainFragment}`;
        if (this.userService.isAuthenticated()) {
          this.timeoutService.initIdle(this.idleTime * 60, this.timeoutTime * 60);
          this.routerEventSubscribe.unsubscribe();
        }
      }
    });

    this.initializeHeader();
    this.initializeFooter();
  }

  ngOnDestroy() {
    if (this.hasLoggedInStateSubscription) {
      this.hasLoggedInStateSubscription.unsubscribe();
    }
  }

  public get isAuthenticated(): boolean {
    return this.userService.isAuthenticated();
  }

  private initializeHeader() {
    this.translateService.get("footer.copyright-text-desktop", { currentYear: new Date().getFullYear() }).subscribe(res => {
      this.copyRightText = res;
    });
    this.translateService.get("footer.copyright-text-mobile", { currentYear: new Date().getFullYear() }).subscribe(res => {
      this.copyRightTextMobile = res;
    });

    let garageHeaderMenuItem: TopNavMenuItem, editHeaderMenuItem: TopNavMenuItem, logoutHeaderMenuItem: TopNavMenuItem;
    let topNavigation: string = 'topNavigation';
    this.translateService.get(topNavigation).subscribe(result => {
      this.mainHeader = {
        logo: new ImageModel("", Constants.miniCaLogoPath, ""),
        menuTitle: result.dropdown.header,
        headerTitle: result.header,
        ariaLabelText: result.logoAriaLabel,
        skipToMainLinkText: result.skip,
        topNavMenuItems: []
      };
      garageHeaderMenuItem = new TopNavMenuItem(result.dropdown.optionGarage, IconType.MyGarage, false, HeaderMenuItemType.MyGarage);
      editHeaderMenuItem = new TopNavMenuItem(result.dropdown.optionEdit, IconType.Edit, false, HeaderMenuItemType.EditUsername);
      logoutHeaderMenuItem = new TopNavMenuItem(result.dropdown.optionLogout, IconType.Logout, false, HeaderMenuItemType.Logout);
      this.mainHeader.topNavMenuItems.push(logoutHeaderMenuItem);
      this.addHeaderMenuItems([garageHeaderMenuItem, editHeaderMenuItem]);
    });

    this.hasLoggedInStateSubscription = this.loginService.hasLoggedInState.subscribe({
      next: (response: any) => {
        this.addHeaderMenuItems([garageHeaderMenuItem, editHeaderMenuItem]);
      }
    });

    this.accessibilityService.setRoleOfOverlay();
  }

  private addHeaderMenuItems(topNavMenuItems: TopNavMenuItem[]) {
    if (this.userService.isTermsAccepted()) {
      this.mainHeader.topNavMenuItems.unshift(...topNavMenuItems);
    }
  }

  public onMenuItemSelect(eventName: HeaderMenuItemType) {
    switch (eventName) {
      case HeaderMenuItemType.MyGarage:
        this.navigateToMyGarage();
        break;
      case HeaderMenuItemType.EditUsername:
        this.editUsername();
        break;
      case HeaderMenuItemType.Logout:
        this.logout();
        break;
    }

  }

  public logoLinkClick() {
    if (this.userService.isTermsAccepted()) {
      this.navigateToMyGarage();
    }
  }

  private navigateToMyGarage() {
    this.routerService.navigateToMyGarage(Constants.myGarageTileMini);
  }

  private editUsername() {
    let language = this.activatedRoute.snapshot.queryParams[Constants.LanguageParameterName];
    let externalUrl = this.urlBuilderService.getEditUsernameRedirectUrl(language);
    this.routerService.navigateToExternalUrl(externalUrl);
  }

  private logout() {
    this.routerService.navigateToSSOLogout();
  }

  private initializeFooter() {
    let privacyFooterMenuItem: FooterMenuModel, termsFooterMenuItem: FooterMenuModel, faqFooterMenuItem: FooterMenuModel, contactUsFooterMenuItem: FooterMenuModel, miniUSAFooterMenuItem: FooterMenuModel;
    let privacyLegalKey: string = 'footer.privacy-legal';
    let termsConditionsKey: string = 'footer.terms-conditions';
    let faqKey: string = 'footer.faq';
    let contactusKey: string = 'footer.contact-us';
    let miniUSAKey: string = 'footer.mini-usa';

    this.translateService.get([privacyLegalKey, termsConditionsKey, faqKey, contactusKey, miniUSAKey]).subscribe(result => {
      privacyFooterMenuItem = new FooterMenuModel(result[privacyLegalKey], FooterMenuItemType.PrivacyAndLegal, result[privacyLegalKey]);
      termsFooterMenuItem = new FooterMenuModel(result[termsConditionsKey], FooterMenuItemType.TermsAndConditions, result[termsConditionsKey]);
      faqFooterMenuItem = new FooterMenuModel(result[faqKey], FooterMenuItemType.FAQ, result[faqKey]);
      contactUsFooterMenuItem = new FooterMenuModel(result[contactusKey], FooterMenuItemType.ContactUs, result[contactusKey]);
      miniUSAFooterMenuItem = new FooterMenuModel(result[miniUSAKey], FooterMenuItemType.MINIUSA, result[miniUSAKey]);
      this.footerMenuItems = [];
      this.addFooterMenuItems([privacyFooterMenuItem, termsFooterMenuItem, faqFooterMenuItem, contactUsFooterMenuItem, miniUSAFooterMenuItem]);
    });
  }

  private addFooterMenuItems(footerMenuItems: FooterMenuModel[]) {
    this.footerMenuItems.unshift(...footerMenuItems);
  }

  public onFooterMenuItemSelect(event: any) {
    const isNewTab = true;
    if (event) {
      switch (event.eventName) {
        case FooterMenuItemType.PrivacyAndLegal:
          let privacyUrl: any; 
          this.store.select(state => state.EnvironmentConfig!.PRIVACY_POLICY_URL).subscribe(x => privacyUrl = x);
          this.routerService.navigateToExternalUrl(privacyUrl, isNewTab);
          break;
        case FooterMenuItemType.TermsAndConditions:
          this.routerService.navigateToTermsView(isNewTab);
          break;
        case FooterMenuItemType.FAQ:
          this.routerService.navigateToFaq();
          break;
        case FooterMenuItemType.ContactUs:
          let contactUsUrl: any;
          this.store.select(state => state.EnvironmentConfig!.CONTACT_US).subscribe(x => contactUsUrl = x);
          this.routerService.navigateToExternalUrl(contactUsUrl, isNewTab);
          break;
        case FooterMenuItemType.MINIUSA:
          let miniUSAUrl: any;
          this.store.select(state => state.EnvironmentConfig!.MY_DOMAIN_URL).subscribe(x => miniUSAUrl = x);
          this.routerService.navigateToExternalUrl(miniUSAUrl, isNewTab);
          break;
      }
    }
  }
}
